/**
 * State for meet
 */
export const state = {
  /**
   * Logged in user camera Stream
   */
  cameraStream: null,

  /**
   * Flag to check, if user CameraStream is ready
   */
  isCameraStreamReady: false,

  /**
   * Flag to listen for peer event
   */
  listenToPeerEvent: false,

  /**
   * Camera facing mode
   */
  cameraFacingMode: "front",

  /**
   * Audio Track from loggedin user camera
   */
  audioTrack: null,

  /**
   * Video Track from loggedin user camera
   */
  videoTrack: null,

  /**
   * Screen share stream from loggedin user screen
   */
  screenShareStream: null,

  /**
   * The peer connection list
   */
  peerConnections: {},

  /**
   * List of rtcRtpSenders
   */
  rtcRtpSenders: {},

  /**
   * Object to store meeting details information
   */
  meetDetails: {
    meetRoomLockStatus: false, // Possible Values: true/false
    isUserAudioMuted: false, // Possible Values: true/false
    isUserVideoMuted: false, // Possible Values: true/false
    enableUnmute: true, // Possible Values: true/false
    screenShare: "multi-share", // Possible Values: single-share/multi-share/false
    enableChat: true, // Possible Values: true/false
    enableWaitRoom: true, // Possible Values: true/false
    recordStatus: false, // Recording status
    videoTrackType: "camera", // Track type. Possible Values: camera, screen
    room: null, // Current room where the user is present. Possible Values: waitRoom, meetRoom, null
    enableMirrorEffect: true,
    meetScheduledTime: null, // Meeting scheduled time,
    hideSelfView: false // Determines if self view is hidden
  },

  /**
   * Participant List (Scheduled Participant + guest user allowed to join by host)
   */
  participantList: [],

  /**
   * Guest participant list
   */
  guestUserList: [],

  /**
   * List of audio input
   */
  audioInput: [],

  /**
   * List of video input
   */
  videoInput: [],

  /**
   * List of audio output
   */
  audioOutput: [],

  /**
   * Selected audio input
   */
  selectedAudioInput: undefined,

  /**
   * Selected Video Input
   */
  selectedVideoInput: undefined,

  /**
   * Selected Audio Output
   */
  selectedAudioOutput: undefined,

  /**
   * Flag to determine if the full screen mode is on.
   */
  fullScreenMode: false,

  /**
   * Storing the host information.
   */
  hostInfo: {
    id: "",
    name: {
      firstName: "",
      middleName: "",
      lastName: ""
    },
    contact: "",
    socketConn: [],
    timezone: ""
  },

  /**
   * Current view to be displayed (gellery View/Speaker View)
   */
  displayView: "speaker",

  /**
   * peer connection id, which is currently pinned
   */
  pinnedPeerId: null,

  /**
   * Flag to determine if end meeting popup is opened.
   */
  isEndMeetPopupOpened: false,

  /**
   * Flag to determine if record dropdown is opened.
   */
  isRecordDrpDwnOpened: false,

  /**
   * meeting join id of the peer
   */
  joinId: null
};
