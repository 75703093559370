<template>
  <div class="modalWindow" id="allow-participant-in">
    <div class="modal-container">
      <div class="modal-body">
        <div id="participant-permission">
          <h4>
            <span class="par-fname">
              {{
                `${
                  participant.userName.firstName
                    ? participant.userName.firstName[0]
                    : ""
                }${
                  participant.userName.lastName
                    ? participant.userName.lastName[0]
                    : ""
                }`.toUpperCase()
              }}
            </span>
            <span class="info-container">
              <span class="info-text">Someone wants to join this call.</span>
              <span
                class="txt-highlight-pri"
                v-html="
                  `${participant.userName.firstName}${
                    participant.userName.middleName
                      ? ' ' + participant.userName.middleName
                      : ''
                  }${
                    participant.userName.lastName
                      ? ' ' + participant.userName.lastName
                      : ''
                  }`
                "
              >
              </span>
            </span>
          </h4>

          <template v-if="participant.userId">
            <p>As the host, you can remove any one at any time.</p>
            <div class="modal-entry-cta">
              <button
                @click="
                  allowParticipant({
                    userId: participant.userId
                  })
                "
                class="btn btn-primary"
              >
                Admit
              </button>
              <button
                class="btn btn-secondary"
                @click="denyParticipant(participant.userId)"
              >
                Deny Entry
              </button>
            </div>
          </template>
          <template v-else>
            <p>
              <span
                class="txt-highlight-pri guest-user-name"
                v-html="
                  `${participant.userName.firstName}${
                    participant.userName.middleName
                      ? ' ' + participant.userName.middleName
                      : ''
                  }${
                    participant.userName.lastName
                      ? ' ' + participant.userName.lastName
                      : ''
                  }`
                "
              ></span>
              is trying to enter the meeting in a non-standard way. If you
              recognize them, it's safe to admit them. If you want to make sure
              they get added as a contact so you'll have a record of them
              attending, click "Link to Contact." Otherwise, you can have them
              join as a guest.
            </p>
            <button
              class="btn btn-primary-outline"
              @click="openContact = !openContact"
            >
              Link To Contact
            </button>
            <button
              class="btn btn-primary-outline"
              @click="
                allowGuest({
                  socketId: participant.socketId
                })
              "
            >
              Join As Guest
            </button>
            <button
              class="btn btn-primary-outline"
              @click="denyGuest(participant.socketId)"
            >
              Deny
            </button>

            <div class="guest-matching" v-if="openContact">
              <h4>
                Your contacts
              </h4>
              <input
                type="text"
                :value="userSearchVal"
                @input="filterSearch($event.target.value)"
                placeholder="Search Contacts"
              />
              <br /><br />
              <div class="container-contacts">
                <div class="custom-scroll scroll-small">
                  <div
                    class="loader"
                    v-if="'pending' === userListFetchStatus"
                  ></div>
                  <template v-else>
                    <div
                      class="radio-container"
                      v-for="user in userList"
                      :key="user.user_id"
                      @click="selectUser(user.user_id)"
                    >
                      <input
                        type="radio"
                        v-model="selectedUser.userId"
                        :value="user.user_id"
                      />
                      <label class="radio" v-html="user.name"> </label>
                    </div>
                  </template>
                </div>
                <div
                  class="error-message no-contact-selected"
                  v-if="showUserSelectErr"
                >
                  {{ "Please Select a Contact to link the guest user" }}
                </div>
              </div>
              <button
                class="btn btn-primary"
                @click="
                  allowGuest({
                    ...selectedUser,
                    socketId: participant.socketId
                  })
                "
              >
                Admit
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import socket from "../../socket/socket";

export default {
  name: "ParticipantPermission",
  props: {
    participant: Object,
    hideParticipantPermission: Function
  },
  data: function() {
    return {
      openContact: false,
      selectedUser: {
        userId: 0
      },
      userSearchVal: "",
      showUserSelectErr: false
    };
  },
  computed: {
    ...mapGetters("meet", {
      guestUserList: "getGuestUserList"
    }),

    ...mapGetters("participant", {
      userListFetchStatus: "getUserListFetchStatus",
      clients: "getClients",
      persons: "getPersons",
      subscribers: "getSubscribers",
      appUsers: "getAppUsers"
    }),

    /**
     * Computed property to return the list of users
     */
    userList() {
      const users = [
        ...this.clients,
        ...this.persons,
        ...this.subscribers,
        ...this.appUsers
      ];

      if (this.userSearchVal.length > 0) {
        return users.filter(user => {
          return (
            user.name
              .toLowerCase()
              .search(this.escape(this.userSearchVal.toLowerCase())) !== -1
          );
        });
      } else {
        return users;
      }
    }
  },
  methods: {
    ...mapMutations("meet", {
      markParticipantAllowedOnParticipantList:
        "markParticipantAllowedOnParticipantList",
      setGuestUserList: "setGuestUserList",
      markParticipantDeniedOnParticipantList:
        "markParticipantDeniedOnParticipantList"
    }),

    /**
     * Function to allow a participant to join a meeting
     */
    allowParticipant(data) {
      // Removes socket id from waiting room
      this.markParticipantAllowedOnParticipantList(data.userId);

      // Emit event to server
      socket.io.emit("allowParticipant", {
        userId: data.userId
      });

      this.hideParticipantPermission();
    },

    /**
     * Function to deny a participant from joining to meet room
     */
    denyParticipant(userId) {
      // Marking participant as denied on participant list
      this.markParticipantDeniedOnParticipantList({
        userId,
        isDenied: true
      });

      // Emit deny participant event to server
      socket.io.emit("denyParticipant", {
        userId
      });
    },

    /**
     * Function to allow a guest to join a meeting
     */
    allowGuest(data) {
      // Removing the socket id from guest user list as well
      const newGuestUserList = [...this.guestUserList];
      const searchedIndex = newGuestUserList.findIndex(
        user => user.socketId === data.socketId
      );
      if (searchedIndex > -1) {
        newGuestUserList.splice(searchedIndex, 1);
      }
      this.setGuestUserList(newGuestUserList);

      // Emit event to server
      socket.io.emit("allowGuestUser", data);

      this.hideParticipantPermission();
    },

    /**
     * Function to deny a guest user from joining to meet room
     */
    denyGuest(socketId) {
      // Removing the socket id from guest user list as well
      const newGuestUserList = [...this.guestUserList];
      const searchedIndex = newGuestUserList.findIndex(
        participant => participant.socketId === socketId
      );
      if (searchedIndex > -1) {
        newGuestUserList.splice(searchedIndex, 1);
      }
      this.setGuestUserList(newGuestUserList);

      // Emit deny participant event to server
      socket.io.emit("denyGuestUser", {
        socketId
      });
    },

    /**
     * Escape the given chars
     */
    escape(s) {
      // eslint-disable-next-line no-useless-escape
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    },

    /**
     * Function to filter with a given search value
     */
    filterSearch(value) {
      this.userSearchVal = value;
    },

    /**
     * Function to select an user from user list
     */
    selectUser(userId) {
      this.selectedUser.userId = userId;
      this.showUserSelectErr = false;
    }
  }
};
</script>

<style scoped>
#allow-participant-in {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  max-width: 600px;
  left: 50%;
  margin-left: -300px;
  max-height: 250px;
  top: 30px;
}
#participant-permission {
  padding-bottom: 10px;
}
#participant-permission p {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}
#participant-permission h4 {
  font-size: 16px;
}
#participant-permission span.par-fname {
  border-radius: 50%;
  background: #4089c5;
  width: 32px;
  height: 32px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
}
#participant-permission button {
  margin-right: 10px;
}
.guest-matching {
  margin: 20px 0 0 0;
  border-top: 1px solid #333;
  padding: 4px 0 15px 0;
}
.guest-matching h4 {
  font-weight: normal;
}
.matched-guest-users {
  padding-top: 10px;
}
.error-message {
  margin: 10px 0;
}
.radio-container {
  display: inline-block;
  width: 50%;
}
input {
  padding: 5px 10px;
  width: calc(100% - 20px);
}
.radio-container label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}
.modalWindow .modal-container {
  transform: translate(-50%, -27%);
}
.info-text {
  display: block;
}
#participant-permission h4 .txt-highlight-pri {
  text-overflow: ellipsis;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
#participant-permission span.par-fname {
  min-width: 32px;
  margin-right: 10px;
}
#participant-permission h4 {
  display: flex;
  align-items: center;
}
.info-container {
  max-width: calc(100% - 45px);
}
#participant-permission button {
  margin-top: 20px;
}
.guest-user-name {
  word-break: break-all;
}

@media (max-width: 640px) {
  #allow-participant-in {
    max-width: 300px;
    margin-left: -151px;
    max-height: 250px;
    top: 50px;
  }
  #participant-permission button {
    margin-bottom: 10px;
  }
  .radio-container {
    display: block;
    width: auto;
  }
  #participant-permission h4 {
    font-size: 13px;
  }
}
</style>
