import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";

export const guestUserEventListener = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      participantList: "getParticipantList",
      guestUserList: "getGuestUserList"
    })
  },
  methods: {
    ...mapMutations({
      setIsGuestUser: "setIsGuestUser"
    }),

    ...mapMutations("meet", {
      setGuestUserList: "setGuestUserList",
      setParticipantList: "setParticipantList"
    }),

    /**
     * Listening to guest user events
     */
    listenToGuestUserEvent() {
      // Listener to be fired when host allows a guest user
      socket.io.on(
        "guestUserAllowed",
        ({ socketId, userId, userName, userPhone, connectCode }) => {
          if (socketId && this.userId === this.hostInfo.id) {
            // Removing the socket id from guest user list
            const newGuestUserList = [...this.guestUserList];
            const searchedIndex = newGuestUserList.findIndex(user => {
              return user.socketId === socketId;
            });
            if (searchedIndex > -1) {
              newGuestUserList.splice(searchedIndex, 1);
              this.setGuestUserList(newGuestUserList);
            }

            // Add new participant to participant list
            if (
              this.participantList.findIndex(
                participant => participant.userId === userId
              ) < 0
            ) {
              this.setParticipantList([
                ...this.participantList,
                {
                  isGuest: true,
                  userId,
                  userName,
                  userPhone,
                  isUserDeniedToMeetRoom: false,
                  waitRoomSocketConn: [],
                  meetRoomSocketConn: [],
                  externalId: 0
                }
              ]);
            }
          } else if (connectCode) {
            // Storing connect code on session storage
            window.sessionStorage.setItem("isGuestUser", true);
            window.sessionStorage.setItem("connectCode", connectCode);
            this.setIsGuestUser(true);

            this.$router.push({
              name: "Home",
              params: {
                meetCode: this.$route.params.meetCode,
                passCode: this.$route.params.passCode
              }
            });
          }
        }
      );

      // Listener to be fired when host denies a guest user
      socket.io.on("guestUserDenied", ({ socketId }) => {
        if (socket.io.id === socketId) {
          // Showing guest user denied alert
          this.hostPermissionStatus =
            GeneralConstant.error.hostDeniedFromJoining;
        } else {
          // Removing the socket id from guest user list
          const newGuestUserList = [...this.guestUserList];
          const searchedIndex = newGuestUserList.findIndex(
            user => user.socketId === socketId
          );
          if (searchedIndex > -1) {
            newGuestUserList.splice(searchedIndex, 1);
            this.setGuestUserList(newGuestUserList);
          }
        }
      });
    }
  }
};
